import React, { Component } from 'react';
import styled from 'styled-components';
import { fonts } from '../../style-utilities/variables';
import { graphql, Link } from 'gatsby';
import { navigate } from '@reach/router';
import { Helmet } from 'react-helmet';
import Swiper from 'react-id-swiper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faStar,
  faCaretUp,
  faPlus,
  faPrint
} from '@fortawesome/free-solid-svg-icons';
import { faPlayCircle, faHeart } from '@fortawesome/free-regular-svg-icons';
import { faPinterestP } from '@fortawesome/free-brands-svg-icons';
import ComponentStyles from '../../brand-styles/templates/recipe-detail.styles';
// import { Link, animateScroll as scroll } from "../node_modules/react-scroll";
import ReactHtmlParser from 'react-html-parser';

//import components
import Heading from '../../components/Heading';
import StyledLink from '../../components/StyledLink';
import ReviewsCount from '../../components/Reviews/ReviewsCount';
import ShareWidget from '../../components/ShareWidget';
import WhereToBuy from '../../components/WhereToBuy';
import RecipeSearchWidget from '../../components/RecipeSearchWidget';
import RecipeCard from '../../components/RecipeCard';
import ShoppingListDrawer from '../../components/ShoppingListDrawer';
import { breakpoints, colors } from '../../style-utilities/variables';
import { ShoppingListStateConsumer } from '../../context/ShoppingListContext';
import PlaceHolderImage from '../../images/placeholder.png';

import FlyoutBasic from '../../components/FlyoutBasic';
import FlyoutContentNotAuthed from '../../components/FlyoutContentNotAuthed';

import FavoritesService from '../../services/favorites.service';
import UserService from '../../services/user.service';
import CraftPaperImage from '../../images/craft-paper.png';

// Print
import RecipeDetailPrint from '../../components/RecipeDetailPrint';
import { PrintTool } from 'react-print-tool';

import ReviewsSection, {
  ReviewSectionStyle
} from '../../components/Reviews/ReviewsSection';
import { timeout } from 'q';
import { areRangesOverlapping } from 'date-fns';

const TopSection = styled.div`
  #hero-section {
    .ImgContainer {
      height: 400px;
      width: 100%;
      background-image: url(${props => props.backgroundImage});
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
    .hero-recipe {
      text-align: center;
      background: peru;
      background-image: url(${CraftPaperImage});
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      padding: 20px 15px;
      color: white;
      .detail {
        margin-top: 0;
        p {
          margin-bottom: 15px;
          padding: 0 50px;
        }
      }
      .time-info img {
        height: 60px;
      }
      h1 {
        margin: 0;
        font-size: 36pt;
      }
      hr,
      a {
        color: white;
      }
    }
  }

  @media (min-width: ${breakpoints.desktop}) {
    #hero-section {
      height: 600px;
      align-items: center;
      display: grid;
      grid-template-columns: 60% 40%;
      grid-template-areas: 'img hero-recipe';
      max-width: 1400px;
      margin: 0 auto;
      .ImgContainer {
        height: 600px;
        position: absolute;
        right: 0;
      }
      .hero-recipe {
        grid-area: hero-recipe;
        padding: 15px;
        z-index: 1;
        border-radius: 5px;
        margin-right: 60px;
        text-align: left;
        .detail p {
          padding: 0;
        }
      }
    }
  }

  .review-watch {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px 0;
    a {
      text-decoration: none;
      margin-left: 14px;
    }
  }

  #shopping-list-open {
    z-index: 200;
    color: ${colors.primary};
    transform: rotate(-90deg);
    right: -90px;
    top: 50%;
    position: fixed;
    cursor: pointer;
    border: none;
    background: white;
    padding: 9px 14px 14px;
    border-top: 1px solid ${colors.primary};
    border-right: 1px solid ${colors.primary};
    border-left: 1px solid ${colors.primary};
  }

  .hero-recipe-info {
    display: flex;
    justify-content: space-between;
    max-width: 400px;
    margin: 0 auto;
    border-bottom: 1px solid white;
    border-top: 1px solid white;
    padding: 6px;
    div {
      flex: 1;
      padding: 0.5em; /* add some padding ?*/
      text-align: center;
      border-right: 1px solid white;
    }
    div:last-child {
      border: none;
    }
    p {
      margin: 0;
    }
    img {
      height: 80px;
    }
  }

  .widget-section {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    flex-direction: column;
    position: relative;
    ul {
      display: flex;
    }
    .icon {
      max-height: 18px;
      color: ${colors.primary};
      margin-right: 6px;
    }
    .a2a_button_pinterest,
    .pin-link {
      text-decoration: none;
    }

    .button {
      border: 1px solid lightgray;
      margin-top: 12px;
      padding: 12px 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${colors.primary};
      :hover {
        opacity: 0.8;
      }
      a:active,
      a:visited,
      a {
        text-decoration: none !important;
      }
      svg {
        width: 25px;
        fill: ${colors.primary};
        color: #163571;
      }
      cursor: pointer;
    }
    a svg {
      color: white;
    }
  }
  @media (min-width: ${breakpoints.tablet}) {
    .widget-section {
      flex-direction: row;
      .button {
        margin: 0 10px;
      }
    }
  }
`;

const RelatedSection = styled.div`
  text-align: center;
  margin: 40px 0 70px;
  #related-recipes {
    max-width: 1600px;
    margin: 40px auto 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  .swiper-container {
    margin: 10px auto;
  }
  .swiper-pagination-bullets {
    /* bottom: 20px !important; */
  }
`;

const InstructionSection = styled.div`
  background: ${colors.primary};
  width: 100%;
  text-align: center;
  padding: 40px 15px 60px;
  color: white;
  text-align: center;
  h1 {
    margin-bottom: 0;
    color: #ffffff;
  }
  ul {
    list-style: none;
    padding: 0;
  }

  li p {
    display: inline-block;
    margin: 0 10px;
  }
  hr {
    margin-bottom: 28px;
  }
  h4 {
    margin-bottom: 0;
  }
  #tags {
    display: flex;
    width: auto;
    flex-wrap: wrap;
    margin-bottom: 20px;
    #tag {
      background: white;
      color: ${colors.primary};
      font-style: italic;
      font-size: 14px;
      margin-right: 5px;
      margin-top: 5px;
      padding: 5px 10px;
    }
  }
  #instruction-container {
    li p {
      margin-left: 31px;
      margin-top: 10px;
    }
    li input {
      margin-right: 10px;
    }
    li {
      margin-bottom: 20px;
    }
    .favorite-button {
      position: relative;
      .inline {
        position: absolute;
      }
    }
  }
  .left-align {
    text-align: left;
  }
  .button-container {
    display: flex;
    flex-direction: column;
  }
  @media (min-width: 500px) {
    .button-container {
      flex-direction: row;
      margin: 0 auto;
      max-width: 700px;
      justify-content: space-between;
    }
  }
  @media (min-width: ${breakpoints.tablet}) {
    #instruction-container {
      width: 80%;
      max-width: ${breakpoints.maxContainer};
      margin: 0 auto;
    }
  }
`;

const RecipeSection = styled.div`
  .ingredient-container {
    width: 100%;
    #ingredient-section {
      display: -ms-grid;
      display: grid;
      -ms-grid-columns: 50% 50%;
      grid-template-columns: 50% 50%;
      width: 80%;
      padding: 40px 0;
      margin: 0 auto;
      h2 {
        color: ${colors.primary};
      }
      .ingredients {
        padding: 20px;
        ul {
          list-style: none;
          padding: 0;
          margin: 0;
          li {
            border-bottom: 1px solid lightgray;
            padding: 10px 0;
            margin: 0 12px;
          }
        }
        .add-to-list svg {
          width: 13px;
          height: 13px;
        }
        button {
          color: ${colors.primary};
          background: white;
          border: 1px solid ${colors.primary};
          padding: 12px 30px;
          margin-top: 18px;
          :hover {
            opacity: 0.8;
            transition: opacity 0.3s ease-out;
          }
        }

        #deselect-button {
          border: none;
          border-bottom: 1px solid ${colors.primary};
          color: ${colors.primary};
          background: transparent;
          cursor: pointer;
          padding: 0;
          margin-top: 24px;
        }
      }
      div.ps-widget {
        display: none !important;
      }
      .product-image {
        text-align: center;
        padding: 30px;
        a {
          text-decoration: none;
        }
        img {
          width: 350px;
          display: block;
          margin: 20px auto;
        }
        h2 {
          font-family: ${fonts.primary}, sans-serif;
          font-size: 36pt;
        }
        #product-title {
          font-size: 20px;
          margin-bottom: 15px;
          color: white;
        }
      }
      h4 {
        margin: 28px 0 6px;
      }
    }
  }
`;

/* PDF View Styles  */
const PDFView = styled.div`
  padding: 100px;
`;

const Div = styled.div`
  scroll-behavior: smooth;
  h4 {
    margin-top: 10px;
  }
  /*CUSTOM SQUARE CHECKBOX  */
  .checkbox-button {
    cursor: pointer;
  }
  input[type='checkbox'] {
    box-sizing: border-box;
    padding: 0;
  }
  input {
    font-size: 1rem;
    line-height: 1.5;
    padding: 11px 23px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0;
    outline: 0;
    background-color: transparent;
  }
  .checkbox-button__input {
    opacity: 0;
    position: absolute;
  }
  .checkbox-button__control {
    position: relative;
    display: inline-block;
    width: 23px;
    height: 23px;
    margin-right: 12px;
    vertical-align: middle;
    background-color: inherit;
    color: black;
    border: 2px solid #666;
  }
  .checkbox-button__input:checked + .checkbox-button__control:after {
    content: '';
    display: block;
    position: absolute;
    top: 5px;
    left: 5px;
    width: 10px;
    height: 10px;
    background-color: black;
  }
  .checkbox-button__input:checked + .checkbox-button__control {
    border-color: black;
  }
  .checkbox-button__control {
    transform: scale(0.75);
  }

  /*CIRCLE CHECKBOX */
  #instruction-container
    .checkbox-button__input:checked
    + .checkbox-button__control:after {
    top: -2px;
    left: -2px;
    background-color: white;
  }
  #instruction-container .checkbox-button__control,
  #instruction-container
    .checkbox-button__input:checked
    + .checkbox-button__control:after {
    width: 19px;
    height: 19px;
    border-radius: 50%;
    color: white;
  }
  #instruction-container .checkbox-button__control {
    border: 2px solid white;
  }
  input[type='checkbox']:checked + .checkbox-button.strike {
    color: blue;
  }

  #caret {
    width: 15px;
    fill: ${colors.primary};
  }

  @media (max-width: ${breakpoints.tablet}) {
    ${RecipeSection} {
      margin: 0;
      #ingredient-section {
        display: block;
        padding: 0 15px;
        width: 100%;
        .ingredients {
          padding: 0;
          ul {
            margin: 15px 0;
            padding: 0;
          }
        }
        .product-image {
          margin-top: 15px;
          img {
            width: 100%;
          }
        }
      }
    }

    ${RelatedSection} {
      #related-recipes {
        margin: 0;
        text-align: center;
      }
      .recipe-card {
        text-align: left;
        margin-bottom: 15px;
      }
      .button {
        margin: 10px 0;
      }
    }
    ${ReviewSectionStyle} {
      width: 100%;
      .review-content {
        display: block;
      }
    }
  }
  .recipe-sub-nav {
    z-index: 100;
    position: sticky;
    position: -webkit-sticky;
    top: 65px;
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
    .sub-nav-container {
      max-width: ${breakpoints.maxContainer};
      margin: 0 auto;
      width: 75%;
      padding: 4px 0;
      .nav-item .to-top {
        color: ${colors.primary};
        font-weight: bold;
        border-right: 1px solid lightgray;
        @media (max-width: 934px) {
          display: none;
        }
      }
      .nav-item {
        text-decoration: none;
        color: white;
        width: 140px;
        display: inline-block;
        text-align: center;
        padding: 10px 0;
      }
      .nav-item:focus,
      .nav-item:hover {
        cursor: pointer;
        opacity: 0.6;
      }
    }
    @media (max-width: ${breakpoints.tablet}) {
      overflow: auto;
      top: 104px;
      white-space: nowrap;
      /* display: none; */
    }
  }

  ${ComponentStyles}
`;

class PreviewRecipe extends Component {
  // local url: http://localhost:8000/preview/recipe?preview=true&id=881
  constructor(props) {
    super(props);

    this.state = {
      viewPort: 'desktop',
      isBrowser: true,
      user: {},
      searchValue: '',
      // currentRecipeTags: this.props.data.wordpressWpRecipes.tags.map(
      //   tag => tag.name
      // ),
      //currentRecipeSlug: this.props.data.wordpressWpRecipes.slug,
      isShopOpen: false,
      itemsToAdd: [],
      isFavorited: false,
      isAuthed: false,
      showNoAuthFlyout: null,
      pdfMode: false,
      currentUrl: '',
      payoad: {},
      id: ''
    };

    this.favoriteService = new FavoritesService();
    this.userService = new UserService();
  }

  componentDidMount() {
    let search = this.props.location.search;
    let params = new URLSearchParams(search);
    let id = params.get('id');
    let wpnonce = params.get('_wpnonce');
    let BASE_URL = `${process.env.WP_BASE_URI}/${
      process.env.ACTIVE_BRAND
    }/wp-json/wp/v2`;

    fetch(`${BASE_URL}/recipes/${id}/revisions/?_wpnonce=${wpnonce}`, {
      mode: 'cors',
      credentials: 'include'
    })
      .then(response => {
        return response.json();
      })
      .then(myJson => {
        if (myJson) {
          // Here we set the latest revision
          this.setState({
            payload: myJson[0]
          });
        }
      });

    const user = this.userService.getAuthenticatedUser();
    this.setState({
      user: user,
      isAuthed: user ? true : false,
      isBrowser: typeof window !== 'undefined',
      viewPort: window.innerWidth >= 900 ? 'desktop' : 'mobile',
      currentUrl: encodeURIComponent(window.location.href)
    });
    const type = 'recipes';
    //const item = this.props.data.wordpressWpRecipes;

    // if (user && item) {
    //   this.favoriteService.getFavorite(user, item, type).then(data => {
    //     if (data && data.userId) {
    //       this.setState({
    //         isFavorited: true
    //       });
    //     }
    //   });
    // }
    // this.setState({
    //   isBrowser: typeof window !== 'undefined',
    //   viewPort: window.innerWidth >= 900 ? 'desktop' : 'mobile'
    // });
    if (this.state.isBrowser) {
      window.addEventListener('resize', this.setViewport);
    }
    // this.setState({
    //   currentUrl: encodeURIComponent(window.location.href)
    // });
  }

  toggleShowNoAuthFlyout = elId => {
    this.setState(prevState => ({
      showNoAuthFlyout: prevState.showNoAuthFlyout === elId ? null : elId
    }));
  };

  toggleFavorite = elId => {
    const user = this.userService.getAuthenticatedUser();
    const type = 'recipes';
    const item = this.props.data.wordpressWpRecipes;
    const confirmMsg =
      'Are you sure you want to remove this recipe from your list?';

    const doToggle = () => {
      this.favoriteService.toggleFavorite(user, item, type).then(result => {
        if (result) {
          this.setState(
            {
              isFavorited: result.slug ? true : false
            },
            () => {
              // here we get the total amount of favs
              this.favoriteService
                .getFavoritesForUser(user, type)
                .then(result => {
                  if (result) {
                    this.props.userContext.updateFavoritesCount(result.length);
                  }
                });
              if (this.props.onFavoriteCallback) {
                this.props.onFavoriteCallback();
              }
            }
          );
        }
      });
    };

    if (user && item) {
      if (this.state.isFavorited) {
        if (window.confirm(confirmMsg)) {
          doToggle();
        }
      } else {
        doToggle();
      }
    } else if (!user) {
      //show no auth flyout
      this.setState({
        isAuthed: false
      });

      this.toggleShowNoAuthFlyout(elId);
    }
  };
  render() {
    const data = this.state.payload;
    const { isFavorited } = this.state;
    return data ? (
      <Div>
        <Helmet>
          <title>{ReactHtmlParser(data.title.rendered)}</title>
        </Helmet>
        <TopSection className="top-section">
          <div id="hero-section">
            <div
              className="ImgContainer"
              style={{
                backgroundImage: `url(${
                  data.acf.thumbnail !== null
                    ? data.acf.thumbnail
                    : PlaceHolderImage
                })`
              }}
            />
            <div className="hero-recipe">
              <h1>{ReactHtmlParser(data.title.rendered)}</h1>
              <div className="review-watch">
                <ReviewsCount type={'recipes'} slug={data.slug} />
              </div>
              <span className="detail">
                {ReactHtmlParser(data.content.rendered)}
              </span>
              <div className="hero-recipe-info">
                <div className="time-info">
                  {/* <img src={acf.thumbnail.source_url} alt="imageIcon" /> */}
                  <p>Prep Time:</p>
                  <p>{data.acf.prep_time ? data.acf.prep_time : '-'}</p>
                </div>
                <div className="time-info">
                  {/* <img src={acf.thumbnail.source_url} alt="imageIcon" /> */}
                  <p>Total Time:</p>
                  <p>{data.acf.total_time ? data.acf.total_time : '-'}</p>
                </div>
                <div className="time-info">
                  {/* <img src={acf.thumbnail.source_url} alt="imageIcon" /> */}
                  <p>Serves:</p>
                  <p>{data.acf.number_served ? data.acf.number_served : '-'}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="widget-section">
            <div>
              <ShareWidget
                className="widget"
                fbMessengerPageName={data.title.rendered}
                hideAPinButton={true}
                pinterestTitle={data.title.rendered}
                pinterest_image_url={
                  data.acf.thumbnail !== null
                    ? data.acf.thumbnail
                    : PlaceHolderImage
                }
              />
            </div>
            <div>
              <a
                className="pin-link"
                data-pin-custom="true"
                href={`https://www.pinterest.com/pin/create/button/?url=${decodeURIComponent(
                  this.state.currentUrl
                )}&media=${
                  data.acf.thumbnail !== null
                    ? data.acf.thumbnail
                    : PlaceHolderImage
                }&description=${data.title.rendered}`}
              >
                <div className="button">
                  <FontAwesomeIcon icon={faPinterestP} /> &nbsp;Pin this Recipe
                </div>
              </a>

              <FlyoutBasic
                styles={{
                  visible:
                    !this.state.isAuthed &&
                    this.state.showNoAuthFlyout === 'favoritesNoAuth1',
                  height: '250px'
                }}
                onClose={this.toggleShowNoAuthFlyout.bind(
                  this,
                  'favoritesNoAuth1'
                )}
                locationId="recipe-detail-widget-flyout"
              >
                <FlyoutContentNotAuthed title="Save & Favorite Recipes" />
              </FlyoutBasic>
            </div>

            <div
              className="button favorite"
              onClick={this.toggleFavorite.bind(this, 'favoritesNoAuth1')}
            >
              <FontAwesomeIcon icon={faHeart} />
              &nbsp;
              {isFavorited ? (
                <span> Remove from Favorites</span>
              ) : (
                <span>Save as Favorite</span>
              )}
            </div>

            {/* <div className="button download">
            <svg
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 14 12"
            >
              <path
                d="M7,1039.36v6.32l-1.7-1.37l-0.62,0.78l2.82,2.27l2.82-2.27l-0.62-0.78L8,1045.66v-6.3H7z M3,1047.36v1h9v-1H3z"
                transform="translate(0 -1038.362)"
              />
            </svg>
            &nbsp;Download
          </div> */}
            {/* {this.state.viewPort === 'desktop' ? (
              <div
                className="button print"
                onClick={e =>
                  this.printModal(e, this.props.data.wordpressWpRecipes)
                }
              >
                <FontAwesomeIcon icon={faPrint} />
                &nbsp;Print this Recipe
              </div>
            ) : null} */}
          </div>

          {/* <button id="shopping-list-open" onClick={this.openShopHandler}>
            Show Shopping List &nbsp;
            <svg
              id="caret"
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="13"
              viewBox="0 0 307.054 307.054"
            >
              <path
                d="M302.445,205.788L164.63,67.959c-6.136-6.13-16.074-6.13-22.203,0L4.597,205.788c-6.129,6.132-6.129,16.069,0,22.201 l11.101,11.101c6.129,6.136,16.076,6.136,22.209,0l115.62-115.626L269.151,239.09c6.128,6.136,16.07,6.136,22.201,0 l11.101-11.101C308.589,221.85,308.589,211.92,302.445,205.788z"
                id="_x34_85._Up"
              />
            </svg>
          </button> */}
        </TopSection>
      </Div>
    ) : null;
  }
}

export default PreviewRecipe;
